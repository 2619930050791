<template>
    <v-container fluid class="pa-0" :style="$vuetify.display.xs ? '' : 'margin-top: 64px'">
        <v-card style="position: relative" variant="flat">
            <video v-if="$vuetify.display.xs" loop autoplay playsinline muted style="width: 100%"
                src="https://app.wellcomefit.ch/img/alibey/alibey_video.mp4"></video>

            <div v-if="!$vuetify.display.xs">
                <v-img :src="header"></v-img>
                <v-img style="position: absolute; top: 20px; right: 20px" width="150" :src="logo"></v-img>
                <div style="position: absolute; top: 200px; right: 20px" class="text-right">
                    <v-card-subtitle style="font-size: 25px" class="pt-5 px-0">31. Mai - 07. Juni</v-card-subtitle>
                    <h3 style="font-size: 35px" class="font-weight-bold">
                        Ali Bey Club Manavgat
                        <br />
                        Antalya
                    </h3>
                </div>
            </div>
            <div v-else>
                <v-card-subtitle class="pt-5">31. Mai - 07. Juni</v-card-subtitle>
                <h3 class="font-weight-bold">Ali Bey Club Manavgat, Antalya</h3>
            </div>

            <v-card-text style="max-width: 700px" class="mx-auto">
                Ein Fitness Center vor Ort, tägliche Groupfitnesskurse, Personaltraining und Ernährungsplanung bringen
                deine Fitness auf ein neues Level und das alles in einer traumhaften Umgebung an der türkischen Riviera.
                <br />
                <br />
                Der Mix aus Sport, Gesundheit, Party und Erholung macht diese Sportwoche zu einem unvergleichlichen
                Erlebnis für dich, deine Freunde und Deine Familie!
            </v-card-text>
            <video class="my-5" v-if="!$vuetify.display.xs" controls style="width: 100%; max-width: 300px"
                src="https://app.wellcomefit.ch/img/alibey/alibey_video.mp4"></video>
            <room-pricing></room-pricing>
            <benefits-list></benefits-list>
            <all-inclusive></all-inclusive>
        </v-card>
    </v-container>
</template>

<script>
import header from '@/assets/images/alibey/header.jpg'
import logo from '@/assets/images/alibey/logo.png'
import RoomPricing from '@/components/alibey/RoomPricing.vue'
import BenefitsList from '@/components/alibey/BenefitsList.vue'
import AllInclusive from '@/components/alibey/AllInclusive.vue'
export default {
    components: { RoomPricing, BenefitsList, AllInclusive },
    data() {
        return {
            header,
            logo,
        }
    },
}
</script>
