<template>
  <v-banner bg-color="#313131" style="z-index: 1000000000" v-if="visible" position="fixed" location="bottom" lines="one">
    <template #actions>
      <v-btn text :to="{ name: 'policy' }">Mehr Informationen</v-btn>
      <v-btn text @click="acceptCookies">Ok</v-btn>
    </template>
    Diese Website verwendet Cookies.
  </v-banner>
</template>

<script>
export default {
  name: "CookieConsent",
  data() {
    return {
      visible: false,
    }
  },
  mounted() {
    // Pruefe, ob bereits eine Zustimmung vorliegt
    const consent = localStorage.getItem("cookie_consent")
    if (!consent) {
      this.visible = true
    }
  },
  methods: {
    acceptCookies() {
      console.log("COOOOKIES")
      localStorage.setItem("cookie_consent", "true")
      this.visible = false
    },
  },
}
</script>

<style scoped>
/* Optionale zusatzliche Styles */
</style>
