<template>
  <v-app>
    <Navbar @triggerDrawer="drawer = !drawer" />
    <router-view style="min-height: calc(100vh - 630px)" />
    <!-- Mobile Drawer (für Burger-Icon) -->
    <NavigationDrawer @scrollTop="scrollTop" @updateDrawer="drawer = !drawer" :drawer="drawer" />
    <Footer @scrollTop="scrollTop" />
    <CookieConsent />
    <Starfall v-if="isChristmas" />
    <!-- V-Dialog zum Anzeigen der Popups -->
    <v-dialog :attach="true" v-model="popupDialog" :max-width="isMobile ? '100%' : '600px'">
      <v-card max-height="80vh" class="d-flex flex-column">
        <v-carousel
          v-if="!showFullText"
          :show-arrows="fetchedPopups.length > 1"
          :continuous="false"
          height="100%"
          v-model="currentPopupIndex"
          hide-delimiters>
          <template v-slot:prev="{ props }">
            <v-btn color="primary" icon="mdi-chevron-left" @click="props.onClick"></v-btn>
          </template>
          <template v-slot:next="{ props }">
            <v-btn color="primary" icon="mdi-chevron-right" @click="props.onClick"></v-btn>
          </template>
          <v-carousel-item v-for="(popup, index) in fetchedPopups" :key="index">
            <img v-if="popup.image && popup.image.length > 2" :src="'https://app.wellcomefit.ch/img/' + popup.image" class="w-100" />
            <v-card-title class="pt-5 pb-0">{{ popup.title }}</v-card-title>
            <v-card-text>
              <div v-html="getShortText(popup.text)" />
            </v-card-text>
          </v-carousel-item>
        </v-carousel>
        <div v-else class="flex-grow-1" style="overflow-y: auto; padding: 16px">
          <v-card-title class="pt-5 pb-0">{{ fetchedPopups[currentPopupIndex].title }}</v-card-title>
          <v-card-text v-html="fetchedPopups[currentPopupIndex].text"></v-card-text>
        </div>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn v-if="!showFullText" variant="text" color="primary" @click="showFullText = true"> Mehr anzeigen </v-btn>
          <v-btn v-else variant="text" color="primary" @click="showFullText = false">Weniger anzeigen</v-btn>
          <v-btn variant="elevated" color="#313131" @click="closePopup">Schliessen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import { mapActions } from "vuex"
import Navbar from "@/components/layout/Navbar"
import NavigationDrawer from "@/components/layout/NavigationDrawer"
import Footer from "@/components/layout/Footer"
import Snowfall from "@/components/Snowfall"
import Starfall from "@/components/Starfall"
import CookieConsent from "@/components/CookieConsent.vue"

export default {
  name: "App",
  components: {
    Navbar,
    NavigationDrawer,
    Footer,
    Snowfall,
    Starfall,
    CookieConsent,
  },
  data() {
    return {
      showFullText: false,
      isChristmas: false,
      drawer: false,
      popupDialog: false,
      currentPopupIndex: 0,
      fetchedPopups: [],
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth < 600
    },
  },
  methods: {
    ...mapActions(["setOrigin"]),

    checkChristmas() {
      const today = this.$moment()
      const startChristmas = this.$moment("2024-12-24")
      const endChristmas = this.$moment("2024-12-26").endOf("day")
      this.isChristmas = today.isBetween(startChristmas, endChristmas, null, "[]")
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    },
    async fetchPopups() {
      let url = "https://app.wellcomefit.ch/api/popups/website"

      try {
        let content = await this.$axios.get(url)
        if (content.status === 200 && content.data) {
          this.fetchedPopups = content.data
          this.cleanupLocalStorage() // Hier führen wir die Bereinigung durch
          this.showPopup()
        }
      } catch (e) {
        console.log("Fehler beim fetchen der Popups")
      }
    },
    showPopup() {
      const currentPath = location.pathname
      let filteredPopups = this.fetchedPopups.filter(
        (popup) => popup.visibility.paths.includes(currentPath) || (popup.visibility.everywhere && currentPath === "/")
      )

      filteredPopups.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

      if (filteredPopups.length > 0) {
        const cooldown = 24 * 60 * 60 * 1000 // 24h in ms
        const now = new Date().getTime()

        // Finde das erste Popup, das den Cooldown bestanden hat
        let popupToShow = filteredPopups.find((popup) => {
          const lastShownKey = `popup_last_shown_${popup._id}`
          const lastShown = localStorage.getItem(lastShownKey)
          return !lastShown || now - parseInt(lastShown) > cooldown
        })

        if (popupToShow) {
          this.fetchedPopups = [popupToShow]
          setTimeout(() => {
            this.currentPopupIndex = 0
            this.popupDialog = true
            // Speichere den Zeitstempel fuers aktuell angezeigte Popup
            const lastShownKey = `popup_last_shown_${popupToShow._id}`
            localStorage.setItem(lastShownKey, now.toString())
          }, 4000)
        }
      }
    },
    closePopup() {
      this.popupDialog = false
      this.showFullText = false
    },
    getShortText(text) {
      const maxLength = 0
      return text.length > maxLength ? text.substring(0, maxLength) : text
    },
    // Neue Methode zur Bereinigung des LocalStorages
    cleanupLocalStorage() {
      // Hole alle Popup-IDs, die aktuell vorhanden sind
      const currentIds = this.fetchedPopups.map((p) => p._id)
      const keysToRemove = []

      // Zuerst alle Keys sammeln, die entfernt werden sollen
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i)
        if (key && key.startsWith("popup_last_shown_")) {
          const popupId = key.replace("popup_last_shown_", "")
          if (!currentIds.includes(popupId)) {
            keysToRemove.push(key)
          }
        }
      }

      // Jetzt alle gesammelten Keys loeschen
      keysToRemove.forEach((key) => {
        localStorage.removeItem(key)
      })
    },
  },
  watch: {
    $route(to, from) {
      if (this.$route.name === "leadViewGoogle" || this.$route.name === "leadViewMeta") {
        this.setOrigin(this.$route.name)
      }
      if (!this.popupDialog) {
        this.showPopup()
      }
    },
  },
  mounted() {},
  created() {
    this.checkChristmas()
    this.fetchPopups()
  },
}
</script>

<style lang="scss">
* {
  white-space: normal !important;
}

.text-h5 {
  @media only screen and (min-width: 1000px) {
    font-size: 1.6rem !important;
  }
}

.v-card-text,
p {
  font-size: 1rem !important;

  @media only screen and (min-width: 1000px) {
    font-size: 1.1rem;
  }
}

#app {
  font-family: Jost, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

* {
  font-family: Jost, Helvetica, Arial, sans-serif;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

/* Webkit-basierten Browsern (Chrome, Safari) */
::-webkit-scrollbar {
  display: none;
  /* Verstecke die Scrollbar */
}

/* Firefox */
* {
  scrollbar-width: none;
  /* Verstecke die Scrollbar in Firefox */
  scrollbar-color: transparent transparent;
  /* Verstecke die Scrollbar-Farben */
}

/* Optionale zusätzliche Regel für alle anderen Browser */
html,
body {
  -ms-overflow-style: none;
  /* Verstecke die Scrollbar in Internet Explorer und Edge */
  overflow: -moz-scrollbars-none;
  /* Verstecke die Scrollbar in älteren Versionen von Firefox */
}

.popup-image {
  width: 100%;
  object-fit: cover;
  /* Für eine gute Darstellung ohne Verzerrung */
}
</style>
