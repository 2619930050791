<template>
  <v-container id="coursePlanGF" fluid class="px-0 mx-0 mb-2">
    <v-row no-gutters style="background-color: #313131">
      <v-col cols="12">
        <v-card-title
          style="max-width: 500px"
          class="text-h6 mx-auto py-5 text-white text-center"
        >
          Finde Deinen Kurs
        </v-card-title>
      </v-col>
      <v-col cols="12">
        <v-divider class="text-white"></v-divider>
      </v-col>
      <v-col cols="12">
        <v-row v-if="false" no-gutters>
          <v-btn
            @click="showFilterOptions = !showFilterOptions"
            color="transparent"
            class="my-2 mx-auto text-white"
            size="x-large"
            variant="flat"
          >
            <v-icon class="mr-2">mdi-filter-variant</v-icon>
            Filter
          </v-btn>
        </v-row>
        <v-row class="mt-5" no-gutters>
          <v-col v-if="selectedStudio" cols="12">
            <div class="d-flex justify-center flex-column">
              <h3 class="text-white text-center">
                {{ getStudioInfosByCode(selectedStudio).name }}
              </h3>
              <p class="text-white text-center">
                {{ getStudioInfosByCode(selectedStudio).adresse }}
              </p>
              <v-btn
                class="my-2 mx-auto"
                color="white"
                variant="outlined"
                @click="resetSearch"
              >
                Studio wechseln
              </v-btn>
            </div>
          </v-col>
          <v-col v-else cols="12" class="px-5">
            <v-autocomplete
              v-model="selectedStudio"
              max-width="500px"
              label="Studio auswählen"
              :items="filteredStudios"
              item-title="name"
              autocomplete="nope"
              item-value="code"
              style="background-color: hsla(0, 0%, 100%, 0.08)"
              append-inner-icon="mdi-map-marker"
              placeholder="Suche nach Kanton, Stadt oder PLZ"
              variant="outlined"
              class="text-white custom-autocomplete mx-auto"
              hide-details
              no-filter
              @input="onInput"
            >
              <template v-slot:item="{ item, index }">
                <v-list-item
                  @click="selectedStudio = item.value"
                  :class="{
                    'border-bottom-except-last':
                      index + 1 !== filteredStudios.length,
                  }"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ getStudioInfosByCode(item.value).name }}
                    </v-list-item-title>

                    <v-list-item-subtitle class="mb-1">
                      {{
                        getStudioInfosByCode(item.value).adresse.split(",")[0]
                      }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="mb-1">
                      {{
                        getStudioInfosByCode(item.value).adresse.split(",")[1]
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col v-if="!errorLoadingCourses" cols="12" class="px-5">
            <v-autocomplete
              ref="courseSearch"
              v-model="selectedCourses"
              label="Kurse auswählen"
              :items="filteredCourses"
              item-title="name"
              autocomplete="nope"
              chips
              closable-chips
              item-value="_id"
              append-inner-icon="mdi-magnify"
              placeholder="Suche nach Kursen"
              variant="outlined"
              style="background-color: hsla(0, 0%, 100%, 0.08)"
              multiple
              class="text-white custom-autocomplete mx-auto mt-2 mb-5"
              hide-details
              max-width="500px"
              @blur="updateCourseView"
              @input="onInputCourse"
            ></v-autocomplete>
          </v-col>
          <v-col
            class="d-flex justify-center mb-5"
            v-if="selectedStudio"
            cols="12"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  color="white"
                  dark
                  variant="outlined"
                  elevation="2"
                  prepend-icon="mdi-download"
                  @click="downloadGFPlan"
                >
                  Wochenplan herunterladen
                </v-btn>
              </template>
              <span>Lade den Wochenplan herunter</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
      <v-row
        style="max-width: 700px"
        class="mx-auto d-flex justify-center"
        no-gutters
      >
        <v-col
          v-for="(day, index) in weekDays"
          :key="index"
          cols="auto"
          sm="1"
          class="px-0 flex-grow-1"
        >
          <v-card
            @click="
              () => {
                selectedDay = day
                selectedDayIndex = index
              }
            "
            :color="isCurrentDay(day) ? 'primary' : 'transparent'"
            class="d-flex flex-column align-center py-2"
            :disabled="!hasCoursesOnDay(day)"
          >
            <div class="text-white">{{ day.format("dd") }}</div>
            <div class="text-white">{{ day.date() }}</div>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
    <div
      v-if="
        !fetchingCourseView &&
        courseList &&
        courseList.list &&
        courseList.list[selectedDayIndex] &&
        courseList.list[selectedDayIndex].length !== 0
      "
      class="d-flex mx-auto my-5"
      style="max-width: 450px"
    >
      <v-row no-gutters class="courseListWrapper mx-5">
        <div class="courseListWrapper-timeline"></div>
        <v-col
          class="py-0"
          v-for="course of courseList.list[selectedDayIndex]"
          cols="12"
        >
          <v-card
            min-height="80"
            class="rounded-lg course-card elevation-6 my-3 ml-15 mr-2 d-flex"
          >
            <span
              class="course-studio"
              v-if="
                !selectedStudio &&
                selectedCourses.length > 0 &&
                course &&
                course.studio &&
                course.studio.name
              "
            >
              {{ course.studio.name }}
            </span>
            <div class="marker"></div>
            <v-row>
              <v-col class="d-flex align-center justify-end" cols="4">
                <lottie-player
                  mode="normal"
                  v-if="!course.icon"
                  style="width: 52px; height: 66px"
                  class="courseList__view-course-lottie"
                  src="https://app.wellcomefit.ch/img/lottiefiles/gf/dumbell.json"
                ></lottie-player>

                <lottie-player
                  mode="normal"
                  v-else
                  style="width: 52px; height: 66px"
                  class="courseList__view-course-lottie"
                  :key="course.icon"
                  :src="
                    'https://app.wellcomefit.ch/api/gf/icons/' + course.icon
                  "
                ></lottie-player>
              </v-col>
              <v-col class="d-flex align-center justify-start" cols="8">
                <v-row class="text-left" no-gutters>
                  <v-col cols="12">
                    <v-card-title
                      class="font-weight-bold pl-0 pr-2 py-0"
                      style="
                        font-size: 16px;
                        white-space: break-spaces;
                        line-height: 1.2;
                      "
                    >
                      {{ course.course.name }}
                    </v-card-title>
                  </v-col>
                  <v-col v-if="false" cols="12">
                    <p class="font-size-small">
                      {{ course.instructors.map((e) => e.vorname).join(", ") }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <p class="font-size-small">
                      {{
                        $moment(selectedDay)
                          .set({
                            hours: course.hours,
                            minutes: course.minutes,
                            seconds: 0,
                            milliseconds: 0,
                          })
                          .format("HH:mm")
                      }}
                      -
                      {{
                        $moment(selectedDay)
                          .set({
                            hours: course.hours,
                            minutes: course.minutes,
                            seconds: 0,
                            milliseconds: 0,
                          })
                          .add(course.duration, "minutes")
                          .format("HH:mm")
                      }}
                      Uhr
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-row v-if="fetchingCourseView">
      <v-col cols="12" class="mb-5 mt-2 justify-center d-flex flex-column">
        <v-progress-circular
          color="primary"
          indeterminate
          class="mx-auto"
        ></v-progress-circular>
        <v-card-text class="text-center">Kurse werden geladen</v-card-text>
      </v-col>
    </v-row>
    <v-row
      v-if="
        !fetchingCourseView &&
        !(this.selectedStudio || this.selectedCourses.length > 0)
      "
      style="max-width: 700px"
      class="d-flex justify-center flex-column mx-auto"
      no-gutters
    >
      <v-card-text class="mt-2 mb-1 text-center">
        Wähle einen Kurs oder einen Standort aus,
        <br />
        um die Suche zu starten
      </v-card-text>
      <v-btn
        v-if="false"
        @click="showFilterOptions = true"
        variant="text"
        class="mx-auto mb-5"
      >
        Auswahl öffnen
      </v-btn>
    </v-row>
    <v-row
      v-if="
        !fetchingCourseView &&
        courseList &&
        courseList.list &&
        courseList.list[selectedDayIndex] &&
        courseList.list[selectedDayIndex].length === 0
      "
      style="max-width: 700px"
      class="d-flex justify-center flex-column mx-auto"
      no-gutters
    >
      <v-alert
        type="info"
        color="primary"
        class="mt-5 mb-1 text-center text-white mx-5"
      >
        Keine Kurse mit den aktuellen Filtern an diesem Tag gefunden
      </v-alert>
      <v-btn
        v-if="false"
        @click="showFilterOptions = true"
        variant="text"
        class="mx-auto mb-5"
      >
        Auswahl öffnen
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex"
export default {
  props: {
    studio: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      weekDays: [],
      selectedDay: null,
      selectedDayIndex: 0,
      showFilterOptions: false,
      selectedStudio: null,
      search: "",
      filteredStudios: [],
      inputTimeout: null,
      backupCourseList: {},
      courseList: {},
      selectedCourses: [],
      allCourses: [],
      searchQuery: "",
      errorLoadingCourses: false,
      fetchingCourseView: false,
    }
  },
  mounted() {
    this.setWeekDays()
  },
  computed: {
    ...mapState({
      studios: (state) => state.studios,
      loadingStudios: (state) => state.loadingStudios,
      loadingStudiosError: (state) => state.loadingStudiosError,
    }),
    filteredCourses() {
      if (this.courseList?.courses) {
        return this.courseList?.courses
          .filter(
            (course, index, self) =>
              index ===
              self.findIndex(
                (c) => c.name.toLowerCase() === course.name.toLowerCase()
              )
          )
          .filter((course) =>
            course.name.toLowerCase().includes(this.searchQuery.toLowerCase())
          )
      }
      return this.allCourses.filter((course) =>
        course.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      )
    },
  },
  watch: {
    selectedCourses(newVal, oldVal) {
      const removedCourse =
        newVal.length < oldVal.length && !this.$refs.courseSearch.isFocused
      if (removedCourse) this.updateCourseView()
    },
    search() {
      this.filterStudios()
      this.selectedStudio = null
    },
    selectedStudio() {
      this.selectedCourses = []
      this.updateCourseView()
    },
  },
  async created() {
    await this.fetchStudios()
    if (this.studio) this.selectedStudio = this.studio
    this.filterStudios()
    this.fetchCourses()
  },
  methods: {
    ...mapActions(["fetchStudios"]),
    downloadGFPlan() {
      const pdfUrl = `https://app.wellcomefit.ch/api/ex/weeklypdf/${this.selectedStudio}`
      this.$axios
        .get(pdfUrl, {
          responseType: "blob",
        })
        .then((response) => {
          // Blob-URL erstellen
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          // Virtuellen Link erzeugen
          const fileLink = document.createElement("a")
          fileLink.href = fileURL
          // Hier den Dateinamen passend setzen
          fileLink.setAttribute("download", "Wochenplan.pdf")
          // Link ins Dokument haengen und Klick simulieren
          document.body.appendChild(fileLink)
          fileLink.click()
          // Link wieder entfernen
          document.body.removeChild(fileLink)
        })
        .catch((err) => {
          console.error("Fehler beim Download: ", err)
        })
    },
    async updateCourseView() {
      this.fetchingCourseView = true

      // Wenn kein Studio und keine Kurse ausgewählt sind, setze die Kursliste zurück
      if (!this.selectedStudio && this.selectedCourses.length === 0) {
        this.fetchingCourseView = false
        this.courseList = {}
        this.backupCourseList = {}
        return
      }

      if (this.selectedStudio) {
        // Wenn ein Studio ausgewählt ist und die Kursliste nicht vorhanden ist, lade die Kursliste per API
        if (
          !this.backupCourseList ||
          Object.keys(this.backupCourseList).length === 0
        ) {
          try {
            let courseList = await this.$axios.get(
              "ex/gf/" + this.selectedStudio
            )
            if (courseList.status === 200 && courseList.data) {
              this.backupCourseList = courseList.data
              this.courseList = this.filterCoursesBySelection(
                this.backupCourseList
              )
            }
          } catch (e) {
            console.log(e)
          }
        } else {
          // Wenn ein Studio ausgewählt ist und die Kursliste bereits vorhanden ist, filtere nur die Kurse
          this.courseList = this.filterCoursesBySelection(this.backupCourseList)
        }
      } else {
        // Wenn kein Studio ausgewählt ist, aber Kursfilter gesetzt sind, fetch Kurse von der API
        try {
          let courseList = await this.$axios.post(
            "website/groupfitness/filter/courses",
            {
              courses: this.selectedCourses,
            }
          )
          if (courseList.status === 200 && courseList.data) {
            this.courseList = this.filterCoursesBySelection(courseList.data)
          }
        } catch (e) {
          console.log(e)
        }
      }

      this.setFirstAvailableDay()
      this.fetchingCourseView = false
    },
    filterCoursesBySelection(courseList) {
      if (this.selectedCourses.length === 0) return courseList
      return {
        ...courseList,
        list: courseList.list.map((dayCourses) =>
          dayCourses.filter((course) =>
            this.selectedCourses.includes(course.course._id)
          )
        ),
      }
    },
    setFirstAvailableDay() {
      for (let i = 0; i < this.weekDays.length; i++) {
        if (this.courseList.list[i] && this.courseList.list[i].length > 0) {
          this.selectedDay = this.weekDays[i]
          this.selectedDayIndex = i
          return
        }
      }
    },
    resetSearch() {
      this.selectedStudio = null
      this.search = ""
      this.backupCourseList = {} // Reset backup list when changing studio
      this.courseList = {} // Reset current course list
    },
    setWeekDays() {
      const start = this.$moment()
      this.selectedDay = start
      for (let i = 0; i < 7; i++) {
        this.weekDays.push(start.clone().add(i, "days"))
      }
    },
    isCurrentDay(day) {
      return day.isSame(this.selectedDay, "day")
    },
    hasCoursesOnDay(day) {
      const dayIndex = this.weekDays.indexOf(day)
      return (
        this.courseList.list &&
        this.courseList.list[dayIndex] &&
        this.courseList.list[dayIndex].length > 0
      )
    },
    getStudioInfosByCode(code) {
      let studioInfos = {
        name: "",
        adresse: "",
      }
      let studio = this.studios.find((e) => e.code === code)
      if (!studio) return studioInfos
      return { name: studio.name, adresse: studio.adresse }
    },
    filterStudios() {
      if (!this.search) {
        this.filteredStudios = this.studios
        return
      }
      const searchLower = this.search.toLowerCase()
      this.filteredStudios = this.studios.filter((studio) => {
        return (
          studio.canton.toLowerCase().includes(searchLower) ||
          studio.art.toLowerCase().includes(searchLower) ||
          studio.name.toLowerCase().includes(searchLower) ||
          studio.adresse.toLowerCase().includes(searchLower) ||
          studio.email.toLowerCase().includes(searchLower) ||
          studio.code.toLowerCase().includes(searchLower)
        )
      })
    },
    onInput(val) {
      clearTimeout(this.inputTimeout)
      this.inputTimeout = setTimeout(() => {
        this.search = val.target.value
      }, 300)
    },
    async fetchCourses() {
      try {
        let courses = await this.$axios.get(
          "website/groupfitness/filter/courses"
        )
        if (courses.status === 200 && courses.data && courses.data.length > 0) {
          this.allCourses = courses.data
        } else {
          this.errorLoadingCourses = true
        }
      } catch (e) {
        this.errorLoadingCourses = true
      }
    },
    onInputCourse(query) {
      this.searchQuery = query.target.value
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .mdi-menu-down {
  display: none;
}
.v-container {
  padding: 0;
  margin: 0;
}
.v-card {
  min-height: 60px;
  border-radius: 0;
  border: 1px solid #ccc;
}

/* Autofill styles reset */
::v-deep .custom-autocomplete input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: #000 !important;
  transition: background-color 5000s ease-in-out 0s;
}
::v-deep .custom-autocomplete input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: #000 !important;
  transition: background-color 5000s ease-in-out 0s;
}
::v-deep .custom-autocomplete .v-input__control {
  min-height: 0px;
}

.border-bottom-except-last {
  border-bottom: 1px solid lightgrey;
}

.courseListWrapper {
  position: relative;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &-timeline {
    height: calc(100% - 25px);
    width: 1px;
    background-color: #00000075;
    left: 20px;
    position: absolute;
    top: 13px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
.course-card {
  background: linear-gradient(
    180deg,
    rgba(209, 223, 223, 1) 0%,
    rgba(136, 199, 181, 1) 79%
  );
  overflow: visible;
  position: relative;
  border: none;
}
.marker {
  height: 13px;
  width: 13px;
  background-color: #88c7b5;
  position: absolute;
  left: -46px;
  border-radius: 50%;
  top: calc(50% - 7px);
  padding: 0px;
  margin: 0px;
}

.font-size-small {
  font-size: 13px;
}
.course-studio {
  text-align: center;
  position: absolute;
  width: 150px;
  top: -10px;
  background-color: #00963f;
  border-radius: 5px;
  left: calc(50% - 75px);
  font-weight: bold;
  color: white;
}
</style>
