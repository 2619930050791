import { createStore } from "vuex"
import axios from "axios" // Importiere Axios
import moment from "moment"

const store = createStore({
  state: {
    studios: [],
    loadingStudios: false,
    loadingStudiosError: false,
    lastLoaded: null,
    origin: "",
  },
  mutations: {
    setOrigin(state, origin) {
      state.origin = origin
    },
    setStudios(state, studios) {
      state.studios = studios
    },
    setLoadingStudios(state, loading) {
      state.loadingStudios = loading
    },
    setLoadingStudiosError(state, error) {
      state.loadingStudiosError = error
    },
    setLastLoaded(state, timestamp) {
      state.lastLoaded = timestamp
    },
  },
  actions: {
    setOrigin({ commit }, origin) {
      commit("setOrigin", origin)
    },
    async fetchStudios({ state, commit }) {
      const now = moment()
      const threeHoursAgo = now.subtract(1, "hours")

      if (state.loadingStudiosError || !state.lastLoaded || moment(state.lastLoaded).isBefore(threeHoursAgo)) {
        commit("setLoadingStudios", true)
        commit("setLoadingStudiosError", false)
        try {
          let response = await axios.get("https://app.wellcomefit.ch/api/studios/fetch/public")
          if (response.status === 200 && response.data) {
            commit("setStudios", response.data)
            commit("setLastLoaded", moment().toISOString())
          }
        } catch (e) {
          commit("setLoadingStudiosError", true)
        }
        commit("setLoadingStudios", false)
      }
    },
  },
  getters: {
    origin: (state) => state.origin,
    studios: (state) => state.studios,
    loadingStudios: (state) => state.loadingStudios,
    loadingStudiosError: (state) => state.loadingStudiosError,
  },
})

export default store
