<template>
  <v-container style="margin-top: 64px" class="mb-5 d-flex align-center">
    <v-row>
      <v-col cols="12" md="6">
        <v-card class="pb-4 mx-5" flat>
          <v-card-title v-if="false" class="pb-5">FAQ</v-card-title>
          <v-expansion-panels v-if="false" flat class="mb-7">
            <v-expansion-panel v-for="(faq, index) in faqs" :key="index">
              <v-expansion-panel-title>
                {{ faq.question }}
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                {{ faq.answer }}
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-card-title class="mt-4">Kontaktiere deinen Club</v-card-title>
          <club-map></club-map>

          <v-card-text>
            Entdecke die Vorteile deines lokalen Clubs und nimm direkt Kontakt auf, um mehr über unser Angebot zu erfahren. Wir sind hier, um deine
            Fitnessziele zu unterstützen!
          </v-card-text>
          <v-btn color="primary" @click="$router.push('/clubs')">Club finden</v-btn>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="pb-4 pt-5 mx-5" flat>
          <v-card-title>Brauchst du noch weitere Hilfe?</v-card-title>
          <v-img
            src="https://app.wellcomefit.ch/api/website/image/image-1728286684312.webp"
            max-height="300px"
            cover
            class="header-image contain"></v-img>
          <v-card-text>
            Wir sind immer für dich da – ob per E-Mail oder Telefon. Unsere E-Mail-Adresse findest du hier. Möchtest du lieber anrufen? Dann schau in
            die Club-Übersicht, dort findest du die Nummer.
          </v-card-text>
          <v-row no-gutters class="text-center">
            <v-col cols="12">
              <v-btn class="no-uppercase px-0 mx-0" variant="text" height="30" size="large" @click="email" :alt="`Email an  info@wellcomefit.ch`">
                <v-icon>mdi-email-outline</v-icon>
                info@wellcomefit.ch
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ClubMap from "@/components/ClubMap.vue"
export default {
  components: { ClubMap },
  beforeRouteEnter(to, from, next) {
    document.title = "Support & Kontakt – well come FIT group"
    const metaDescription = document.querySelector('meta[name="description"]')
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Kontaktiere uns für alle Fragen rund um unsere Fitnessclubs. Wir sind per Telefon oder E-Mail erreichbar, um dir weiterzuhelfen."
      )
    }
    next()
  },
  methods: {
    call() {
      window.location.href = `tel:052 721 36 92`
    },
    email() {
      window.location.href = `mailto:info@wellcomefit.ch`
    },
  },
  data() {
    return {
      faqs: [
        {
          question: "What is Lorem Ipsum?",
          answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
        {
          question: "Where does it come from?",
          answer: "Contrary to popular belief, Lorem Ipsum is not simply random text.",
        },
        {
          question: "Why do we use it?",
          answer: "It is a long established fact that a reader will be distracted by the readable content.",
        },
        {
          question: "Where can I get some?",
          answer: "There are many variations of passages of Lorem Ipsum available.",
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .no-uppercase {
  text-transform: unset !important;
  letter-spacing: normal !important;
}
</style>
