<template>
  <v-container id="hyrox" fluid class="hyrox-section" pa-0>
    <v-row align="center" justify="center">
      <!-- Bildbereich (auf Mobile zuerst, auf Desktop rechts) -->
      <v-col align="center" cols="12" md="6" order="1" order-md="2">
        <v-img :src="hyroxImage" class="hyrox-image"></v-img>
      </v-col>
      <!-- Textbereich (auf Mobile danach, auf Desktop links) -->
      <v-col cols="12" md="6" order="2" order-md="1">
        <div class="hyrox-text">
          <h2 class="hyrox-title">HYROX</h2>
          <p class="hyrox-description">
            Erlebe das perfekte Zusammenspiel aus Ausdauer und Kraft!<br />
            HYROX ist der neue Fitnesstrend für alle, die ihre Fitness auf das nächste Level bringen wollen und ihr Fitnesslevel in einem Wettkampf
            vergleichen möchten.<br />
            Wir bereiten Dich an unserem <span class="locationLink" @click="$router.push('/clubs/frauenfeld')">Standort in Frauenfeld</span> mit
            speziellen Kursen und individuellen Trainingsplänen optimal auf dein race vor.<br />
            Werde mit uns jetzt Teil der globalen HYROX-Community.
          </p>
          <p class="hyrox-highlight">🏋️‍♂️ Kraft. Ausdauer. Performance. Bist du bereit?</p>
          <!-- Toggle-Button -->
          <v-btn class="hyrox-toggle-btn" text color="#ffc107" @click="toggleDetails">
            Was ist eigentlich HYROX?
            <v-icon right>{{ showDetails ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
          </v-btn>
          <!-- Detaillierter Text, mit Transition -->
          <v-slide-y-transition>
            <div v-if="showDetails" class="hyrox-details">
              <p class="hyrox-description">
                HYROX ist ein Wettkampf, in dem Du Deine Ausdauer, Kraft und Technik unter Beweis stellen kannst. Du durchläufst verschiedene
                Stationen, die Du jeweils mit einem Kilometer Laufen kombinierst. Die Stationen sind:
              </p>
              <ul class="hyrox-list">
                <li>
                  <v-icon class="list-icon" left>mdi-ski-cross-country</v-icon>
                  SkiErg
                </li>
                <li>
                  <v-icon class="list-icon" left>mdi-sledding</v-icon>
                  Schlitten schieben
                </li>
                <li>
                  <v-icon class="list-icon" left>mdi-tow-truck</v-icon>
                  Schlitten ziehen
                </li>
                <li>
                  <v-icon class="list-icon" left>mdi-run-fast</v-icon>
                  Burpee-Weitsprung
                </li>
                <li>
                  <v-icon class="list-icon" left>mdi-rowing</v-icon>
                  Rudern
                </li>
                <li>
                  <v-icon class="list-icon" left>mdi-dumbbell</v-icon>
                  Farmers Carry
                </li>
                <li>
                  <v-icon class="list-icon" left>mdi-weight</v-icon>
                  Sandbag Lunges
                </li>
                <li>
                  <v-icon class="list-icon" left>mdi-basketball</v-icon>
                  Wall Balls
                </li>
              </ul>
            </div>
          </v-slide-y-transition>
          <p class="hyrox-description">
            Das HYROX-Race als krönender Abschluss: Am Ende einer HYROX-Challenge steht ein HYROX Race. Dieses Jahr kannst Du an einem HYROX Race im
            Oktober in Genf teilnehmen, oder im Frühjahr nächsten Jahres wieder in St. Gallen auf dem Olma-Gelände oder in Zürich. Freunde und Familie
            können dabei sein und Dich bei Deinem HYROX-Race anfeuern.
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import hyroxImage from "@/assets/images/hyrox/square.jpg"

export default {
  name: "HyroxSection",
  data() {
    return {
      hyroxImage,
      showDetails: false,
    }
  },
  methods: {
    toggleDetails() {
      this.showDetails = !this.showDetails
    },
  },
}
</script>

<style scoped>
.locationLink {
  text-decoration: underline;
  font-weight: bold;
}
.hyrox-section {
  background-color: #000;
  color: #fff;
  padding: 40px 20px;
}

.hyrox-title {
  color: #ffc107; /* Gelb als Akzent */
  font-weight: bold;
  margin-bottom: 20px;
}

.hyrox-text {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  text-align: left;
}

.hyrox-description {
  margin: 15px 0;
  line-height: 1.6;
}

.hyrox-highlight {
  font-size: 1.2em;
  font-weight: bold;
  margin: 15px 0;
}

.hyrox-list {
  list-style: none;
  padding-left: 0;
  margin: 15px 0;
}

.hyrox-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.list-icon {
  margin-right: 10px;
  font-size: 1.2rem;
  color: #ffc107;
}

.hyrox-image {
  border-radius: 8px;
  max-width: min(400px, 80%);
  margin: auto;
}

.hyrox-toggle-btn {
  font-weight: bold;
  margin-bottom: 15px;
}

@media (max-width: 960px) {
  .hyrox-section {
    padding: 20px 10px;
  }
  .hyrox-image {
    margin-top: 50px;
  }
}
</style>
