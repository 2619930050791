<template>
  <v-card
    :class="{
      'mt-5': $vuetify.display.smAndDown,
      'mt-2': !$vuetify.display.smAndDown,
    }"
    flat
  >
    <v-row
      class="mx-auto"
      v-if="selected && !loading && selectedStudio.openingHours"
      no-gutters
    >
      <v-col cols="12" class="justify-content-center">
        <v-card-title
          class="justify-content-center text-h5"
          v-if="selectedOpeningHours"
        >
          Öffnungszeiten
        </v-card-title>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-chip
          class="ma-1"
          :color="selectedOpeningHours === 'Checkin' ? 'primary' : ''"
          @click="selectedOpeningHours = 'Checkin'"
          v-if="selectedStudio.alwaysCheckin"
        >
          Checkin
        </v-chip>
        <v-chip
          class="ma-1"
          :color="selectedOpeningHours === openingHours.title ? 'primary' : ''"
          @click="selectedOpeningHours = openingHours.title"
          v-for="(openingHours, index) of selectedStudio.openingHours"
          :key="'chip' + index"
        >
          {{ openingHours.title }}
        </v-chip>
      </v-col>
      <v-col
        cols="12"
        v-if="
          selectedStudio.alwaysCheckin && selectedOpeningHours === 'Checkin'
        "
      >
        <v-table>
          <thead>
            <tr>
              <th class="text-left">Checkin</th>
              <th class="text-left">Zeiten</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="width: 40%">MO - SO / FT</td>
              <td style="width: 60%">24H</td>
            </tr>
          </tbody>
        </v-table>
      </v-col>
      <v-col
        cols="12"
        v-for="(openingHours, index) of selectedStudio.openingHours.filter(
          (oh) => oh.title === selectedOpeningHours
        )"
        :key="'openingHours' + index"
      >
        <v-table>
          <thead>
            <tr>
              <th class="text-left">{{ openingHours.title }}</th>
              <th class="text-left">Zeiten</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(hours, index) of openingHours.hours"
              :key="openingHours.title + index"
            >
              <td style="width: 40%">{{ hours.text }}</td>
              <td style="width: 60%">
                <span>
                  {{ hours.from + " - " + hours.to }}
                </span>
                <span v-if="hours.from2 && hours.to2">
                  <br />
                  {{ hours.from2 + " - " + hours.to2 }}
                </span>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-col>
      <v-col cols="12">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mt-2"
              v-on="on"
              variant="text"
              color="primary"
              dark
              prepend-icon="mdi-calendar"
              @click="scrollToGFList"
            >
              Zum Group Fitness Plan
            </v-btn>
          </template>
          <span>Scrollt zum Group Fitness Kursplan</span>
        </v-tooltip>
      </v-col>
      <div height="300px" v-if="selected && !loading"></div>

      <v-row v-if="loading">
        <v-col cols="12" class="d-flex justify-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    selectedStudio: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: true,
      loading: false,
      selectedOpeningHours: "",
    }
  },
  created() {
    const selectedStudio = JSON.parse(JSON.stringify(this.selectedStudio))

    this.selectedOpeningHours = selectedStudio.alwaysCheckin
      ? "Checkin"
      : selectedStudio.openingHours && selectedStudio.openingHours.length
      ? selectedStudio.openingHours[0].title
      : null
  },
  methods: {
    scrollToGFList() {
      const dialogCard = document.getElementById("dialogCard")
      const coursePlanGF = document.getElementById("coursePlanGF")

      if (coursePlanGF && dialogCard) {
        // Hole die Position des Containers und des Ziel-Elements relativ zum Viewport
        const containerRect = dialogCard.getBoundingClientRect()
        const elementRect = coursePlanGF.getBoundingClientRect()

        const navbarHeight = 64
        const offsetTop =
          elementRect.top -
          containerRect.top +
          dialogCard.scrollTop -
          navbarHeight

        // Sanftes Scrollen innerhalb des Containers
        dialogCard.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        })
      }
    },
  },
}
</script>
